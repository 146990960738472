<template>
  <div :id="$style.createTracksList">
    <!-- <h2 :class="styles.heading">Create A New List . . .</h2> -->
    <input
      :class="[styles.heading, styles.inputAsHeading]"
      v-model="listName"
      placeholder="Enter a name for the list"
    />

    <BaseButton
      :class="$style.cta"
      @click="createList"
      :disabled="createListStatusPending"
      >{{ createListStatusPending ? "Creating..." : "Create" }}</BaseButton
    >
  </div>
</template>
<script>
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { withAsync } from "@/helpers";
import { API_STATUS } from "@/constants/apiStatus";
import { createUserTracksList } from "@/api/userTracksListApi";
const { IDLE, PENDING, SUCCESS, ERROR, LOADING_MORE } = API_STATUS;

export default {
  name: "CreateTracksList",
  props: {
    styles: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      listName: "",
      createListStatus: IDLE,
    };
  },
  computed: {
    ...apiStatusComputedFactory("createListStatus"),
  },
  methods: {
    async createList() {
      if (!this.listName) return;
      this.createListStatus = PENDING;
      const { response, error } = await withAsync(() =>
        createUserTracksList({
          name: this.listName,
        })
      );
      console.log("resp?", response, error);
      if (error) {
        this.createListStatus = ERROR;
        return;
      }
      this.createListStatus = SUCCESS;
      this.$emit("changeCurrentComponent", "SAVE_TRACKS");
    },
  },
};
</script>
<style lang="scss" module>
#createTracksList {
  display: flex;
  flex-direction: column;
  height: 100%;
  .input {
    width: 100%;
    margin-top: 2rem;
    padding: 1rem;
    width: calc(100% - 2rem);
    border: 1px solid $color-grey-light;
  }

  .cta {
    padding: 1rem;
    width: 100%;
    margin-top: auto;
    background-color: $color-primary;
    color: white;
  }
}
</style>